/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import {
  useBoolean,
  Button,
  Divider,
  HStack,
  VStack,
  Spacer,
  Heading,
  Text,
  Input,
  Checkbox,
  Tooltip,
} from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import i18n from '../../translations/i18n';
import DataGrid from '../core/components/data-grid';
import { includesSome } from '../utils';
import { selectAccountRoles } from '../features/session';
import { loadData, selectSummary, selectAllRowsCount } from '../features/spp-sales-everything';
import { selectContractor, selectContractorView } from '../features/account';

const dateFormatMask = 'yyyy-mm-dd';

const SppSalesEverythingFilterBar = ({ filter, setFilter, admin }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [useFromDate, setUseFromDate] = useBoolean();
  const [useToDate, setUseToDate] = useBoolean();
  const [endsOnly, setEndsOnly] = useBoolean();
  const [useDevPLC1Dev, setUseDevPLC1Dev] = useBoolean(true);
  const [useDevPLC2Dev, setUseDevPLC2Dev] = useBoolean(true);
  const [dateFrom, setDateFrom] = useState(dateFormat(Date.parse(Date.now()), dateFormatMask));
  const [dateTo, setDateTo] = useState(dateFormat(Date.parse(Date.now()), dateFormatMask));
  const handleClearFilters = () => {
    setUseFromDate.off();
    setUseToDate.off();
    setDateFrom(dateFormat(Date.parse(Date.now()), dateFormatMask));
    setDateTo(dateFormat(Date.parse(Date.now()), dateFormatMask));
    setUseDevPLC1Dev.on();
    setUseDevPLC2Dev.on();

    setFilter([]);
  };

  const handleApplyFilters = () => {
    const newFilter = [];

    let dateOperator = null;
    let dateValue = null;
    if (dateFrom !== '' && useFromDate && dateTo !== '' && useToDate) {
      dateOperator = 'inrange';
      dateValue = {
        start: dateFormat(Date.parse(dateFrom), dateFormatMask),
        end: dateFormat(Date.parse(dateTo), dateFormatMask),
      };
    } else if (dateFrom !== '' && useFromDate) {
      dateOperator = 'afterOrOn';
      dateValue = dateFormat(Date.parse(dateFrom), dateFormatMask);
    } else if (dateTo !== '' && useToDate) {
      dateOperator = 'beforeOrOn';
      dateValue = dateFormat(Date.parse(dateTo), dateFormatMask);
    } else {
      dateOperator = null;
    }

    if (useFromDate || useToDate) {
      newFilter.push({
        name: 'createdAt',
        operator: dateOperator,
        type: 'date',
        value: dateValue,
      });
    }

    if (endsOnly === true) {
      newFilter.push({
        name: 'operType',
        operator: 'eq',
        type: 'string',
        value: 'Zakończenie transakcji',
      });
    }

    if (!useDevPLC1Dev || !useDevPLC2Dev) {
      if (useDevPLC1Dev) {
        newFilter.push({
          name: 'deviceName',
          operator: 'eq',
          type: 'string',
          value: 'PLC01-DEV',
        });
      } else if (useDevPLC2Dev) {
          newFilter.push({
            name: 'deviceName',
            operator: 'eq',
            type: 'string',
            value: 'PLC02-DEV',
          });
        } else {
        newFilter.push({
          name: 'deviceName',
          operator: 'eq',
          type: 'string',
          value: 'cb0732b4',
        });
      }
    }

    setFilter(newFilter);
  };

  const handleTodayClick = () => {
    const now = Date.now();
    const fromDate = dateFormat(Date.parse(now), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handleEndsOnlyClick = () => {
    setEndsOnly.toggle();
    handleApplyFilters();
  };
  const handleCurrentWeekClick = () => {
    const now = Date.now();
    let day = new Date().getDay();
    if (day === 0) {
      day = 6;
    } else {
      day -= 1;
    }
    const fromDate = dateFormat(Date.parse(new Date(now - day * 24 * 60 * 60 * 1000)), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handleCurrentMonthClick = () => {
    const now = Date.now();
    const day = new Date().getDate() - 1;
    const fromDate = dateFormat(Date.parse(new Date(now - day * 24 * 60 * 60 * 1000)), dateFormatMask);
    const toDate = dateFormat(Date.parse(now), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  const handlePreviousMonthClick = () => {
    const date = new Date();
    const now = Date.now();
    const dayTo = new Date().getDate();
    const fromDate = dateFormat(Date.parse(new Date(date.getFullYear(), date.getMonth() - 1, 1)), dateFormatMask);
    const toDate = dateFormat(Date.parse(new Date(now - dayTo * 24 * 60 * 60 * 1000)), dateFormatMask);
    setDateFrom(fromDate);
    setDateTo(toDate);
    setUseFromDate.on();
    setUseToDate.on();
    setFilter([
      {
        name: 'createdAt',
        operator: 'inrange',
        type: 'date',
        value: {
          start: fromDate,
          end: toDate,
        },
      },
    ]);
  };
  return (
    <VStack alignSelf="stretch" alignContent="flex-start" alignItems="flex-start">
      <Heading disabled colorScheme="brand" size="sm">
        {t('modules.sppSalesEverything.Filters', 'Filters')}
      </Heading>
      {/* <Divider orientation="horizontal" />
      <Button minWidth={204} colorScheme="brand" size="sm" onClick={handleEndsOnlyClick}>
        {t('modules.sppSalesEverything.OnlyEndsTransactions', 'Only ends of transactions')}
      </Button> */}
      <Divider orientation="horizontal" />
      <Checkbox colorScheme="brand" isChecked={useFromDate} onChange={setUseFromDate.toggle}>
        {t('modules.sppSalesEverything.FromDate', 'From date')}
      </Checkbox>
      <Input
        focusBorderColor="brand.500"
        disabled={!useFromDate}
        minWidth={196}
        type="date"
        size="xs"
        value={dateFrom}
        onChange={(event) => setDateFrom(event.currentTarget.value)}
      />
      <Checkbox colorScheme="brand" isChecked={useToDate} onChange={setUseToDate.toggle}>
        {t('modules.sppSalesEverything.ToDate', 'To date')}
      </Checkbox>
      <Input
        focusBorderColor="brand.500"
        minWidth={196}
        disabled={!useToDate}
        size="xs"
        type="date"
        value={dateTo}
        onChange={(event) => setDateTo(event.currentTarget.value)}
      />
      <Divider orientation="horizontal" />
      <Checkbox colorScheme="brand" isChecked={useDevPLC1Dev} onChange={setUseDevPLC1Dev.toggle}>
        PLC01-DEV
      </Checkbox>
      <Checkbox colorScheme="brand" isChecked={useDevPLC2Dev} onChange={setUseDevPLC2Dev.toggle}>
        PLC02-DEV
      </Checkbox>
      <Spacer />
      <Divider orientation="horizontal" />
      <Button colorScheme="brand" minWidth={204} onClick={handleApplyFilters}>
        {t('modules.sppSalesEverything.ApplyFilters', 'Apply filters')}
      </Button>
      <Button colorScheme="gray" minWidth={204} onClick={handleClearFilters}>
        {t('modules.sppSalesEverything.ClearFilters', 'Clear filters')}
      </Button>
    </VStack>
  );
};

// const rowStyle = ({ data }) => ({
//   color: data.typeId !== 6 && data.value > 0 ? 'green' : null,
// });
const rowStyle = ({ data }) => {
  if (data.task === 'Moduł zarządzania terminalem') {
    return { background: '#ececec' };
  }

  if (data.task === 'Aplikacja płatnicza') {
    if (data.operType === 'Informacja o postępie') {
      return { background: '#d7f4e3' };
    }

    if (data.operType === 'Zakończenie transakcji') {
      if (data.operData === 'Transakcja zakończona poprawnie - akceptacja') {
        return { background: '#afe9c6' };
      }

      return { background: '#de87aa' };
    }
  }

  return { color: null };
};

const defaultColumns = ({ admin }) => [
  {
    name: 'index',
    type: 'number',
    header: i18n.t('modules.sppSalesEverything.Index', 'No'),
    textAlign: 'end',
    maxWidth: 75,
    sortable: false,
  },
  { name: 'createdAt', header: i18n.t('modules.sppSalesEverything.Date', 'Date'), sortable: false },
  { name: 'dateTime', header: i18n.t('modules.sppSalesEverything.DateTime', 'Date time'), allowUnsort: false },
  {
    name: 'transactionType',
    header: i18n.t('modules.sppSalesEverything.TransactionType', 'Transaction type'),
    sortable: false,
    minWidth: 250,
  },
  { name: 'amount', header: i18n.t('modules.sppSalesEverything.Amount', 'Amount'), sortable: false, textAlign: 'end' },
  {
    name: 'paymentType',
    header: i18n.t('modules.sppSalesEverything.PaymentType', 'Payment type'),
    sortable: false,
  },
  {
    name: 'deviceName',
    header: i18n.t('modules.sppSalesEverything.DeviceName', 'Device name'),
    sortable: false,
  },
];
// eslint-disable-next-line react/prop-types
const SppSalesEverything = ({ height, contractorId, upAdmin }) => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({});
  const accountRoles = useSelector(selectAccountRoles);
  const dataSource = useCallback(loadData(dispatch)({ filter }), [contractorId, filter, dispatch]);
  const { loginContractor } = useSelector(selectContractor) ?? {};
  const { loginContractorView } = useSelector(selectContractorView) ?? {};
  const admin = includesSome(['admin', 'backoffice'], accountRoles) && loginContractor === loginContractorView;

  return (
    <VStack alignItems="stretch">
      <HStack alignItems="flex-start">
        <SppSalesEverythingFilterBar admin={admin} filter={filter} setFilter={setFilter} contractorId={contractorId} />
        <Divider orientation="vertical" />
        <DataGrid
          height={height}
          dataSource={dataSource}
          columns={defaultColumns({ admin })}
          pagination="remote"
          skip={0}
          limit={50}
          sortInfo={{ name: 'dateTime', dir: -1 }}
          allowUnsort={false}
          idProperty="index"
          rowStyle={rowStyle}
        />
      </HStack>
    </VStack>
  );
};
export default SppSalesEverything;
